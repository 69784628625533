<template>
  <a-drawer
    v-model:visible="visible"
    :maskClosable="false"
    :keyboard="false"
    width="100%"
    :title="$t('CM_Curriculum')"
  >
    <div class="detail">
      <div class="title-wrap">{{ $t("lecturer.basic_info") }}</div>
      <a-form
        class="edit-form-wrap"
        layout="vertical"
        ref="formRef"
        :model="courseDetail"
      >
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item
              :label="$t('LB_Group_CoverPhoto')"
            >
              <img :src="courseDetail.coverImg" alt="" width="160" height="100" />
            </a-form-item>
          </a-col>
          <a-col :span="12"></a-col>
          <a-col :span="12">
            <a-form-item
              :label="$t('CourseName')"
            >
              <a-input
                :maxlength="50"
                v-model:value="courseDetail.courseName"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12"></a-col>
          <a-col :span="12">
            <a-form-item>
              <template #label>
                {{ $t('CourseType') }}
                <a-tooltip placement="right" :title="$t('CourseTypeTips')">
                  <QuestionCircleOutlined class="label-tips" />
                </a-tooltip>
              </template>
              <a-radio-group
                v-model:value="courseDetail.courseType"
                :disabled="disabled"
              >
                <a-radio :value="1">{{ $t('NonStandardCourses') }}</a-radio>
                <a-radio :value="2">{{ $t('Scorm12Course') }}</a-radio>
                <a-radio :value="6" v-if="platformConfig.useVrcourse == 1">
                  {{ $t('VRCourse') }}
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12"></a-col>
          <a-col :span="12">
            <a-form-item
              :label="$t('course.course_catalog')"
            >
              <a-input
                :maxlength="50"
                v-model:value="courseDetail.folderName"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              :label="$t('CourseId')"
            >
              <a-input
                :maxlength="50"
                v-model:value="courseDetail.serialCode"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              :label="$t('CourseLecturer')"
            >
              <a-select
                v-model:value="courseDetail.teacher"
                mode="tags"
                :token-separators="['$']"
                :disabled="disabled"
              ></a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              :label="$t('Labels')"
            >
              <a-select
                v-model:value="courseDetail.label"
                mode="tags"
                :token-separators="['$']"
                :disabled="disabled"
              ></a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              :label="$t('CourseDevelopmentTime')"
            >
              <a-date-picker
                style="width: 100%;"
                v-model:value="courseDetail.developmentTime"
                format="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
              :label="$t('course.course_intro')"
            >
              <a-textarea
                showCount
                v-model:value="courseDetail.intro"
                :rows="4"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div class="title-wrap">{{ $t("SectionContent") }}</div>
      <a-table
        class="ant-table-striped"
        size="middle"
        :columns="state.columns"
        :data-source="state.dataSource"
        :pagination="false"
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        rowKey="documentId"
      >
        <template #index="{ index }">
          {{ index + 1 }}
        </template>
        <template #name="{ record }">
          <span style="cursor: pointer; padding-left: 0" class="keyDray" @click="preview(record)">
            <span>
              <a-tooltip placement="topLeft">
                <template #title>{{ record.fileName }}</template>
                {{ record.fileName }}
              </a-tooltip>
            </span>
          </span>
        </template>
      </a-table>
    </div>
    <div class="footer">
      <!-- 取消 -->
      <a-button @click="visible = false">{{ $t("CM_Cancel") }}</a-button>
    </div>
  </a-drawer>
  <docPreview
    :isVisible="stateView.previewVisible"
    :fileStatus="stateView.fileStatus"
    :fileType="stateView.fileType"
    :filePath="stateView.filePath"
    :fileName="stateView.fileName"
    :fileHome="stateView.fileHome"
    @previewEnd="
      e => {
        stateView.previewVisible = e;
      }
    "
  />
</template>
<script setup>
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { ref, reactive, computed } from "vue";
import docPreview from '@/components/preview/index';
import { formatSeconds, getFileSize, dateFormat } from '@/utils/tools';

const { t: $t } = useI18n();
const store = useStore();
const platformConfig = computed(() => store.getters.platformConfig);
const visible = ref(false);

const courseDetail = ref({
  planId: 0,
  secretData: 0,
  planName: "",
  demandCollectionId: 0,
  demandCollectionName: "",
  demandIds: [],
  hostDepartmentId: "",
  implementDepartmentId: "",
  trainingTime: [],
  budgetAmount: 0,
});
const disabled = ref(true);
const state = reactive({
  columns: [
    {
      title: $t('practice.serial_number'),
      dataIndex: 'index',
      width: 60,
      slots: { customRender: 'index' },
    },
    {
      title: $t('CM_LB_Name'),
      key: 'fileName',
      dataIndex: 'fileName',
      width: 230,
      slots: { customRender: 'name' },
    },
    {
      title: $t('XB_LearningDuration'),
      key: 'times',
      width: 160,
      dataIndex: 'times',
      customRender: ({ text }) => {
        return formatSeconds(text);
      },
    },
    {
      title: $t('Size'),
      key: 'fileSize',
      width: 110,
      dataIndex: 'fileSize',
      customRender: ({ text }) => {
        return text ? getFileSize(text) : '--';
      },
    },
  ],
  dataSource: [],
});

function show(data) {
  courseDetail.value = { ...data };
  if (data.lecturers) {
    courseDetail.value.teacher = data.lecturers.map(item => item.name);
  }
  if (data.labels) {
    courseDetail.value.label = data.labels.split(',');
  }
  if (data.developmentTime) {
    courseDetail.value.developmentTime = dateFormat(data.developmentTime);
  }
  state.dataSource = data.details || [];
  visible.value = true;
}
const stateView = reactive({
  previewVisible: false,
  fileStatus: 1,
  fileType: '',
  filePath: '',
  fileName: '',
  fileHome: '',
});
function preview(data) {
  stateView.previewVisible = true;
  stateView.fileStatus = data.status;
  stateView.fileType = data.fileType;
  stateView.filePath = data.filePath;
  stateView.fileName = data.fileName || '';
  stateView.fileHome = data.home || '';
};

defineExpose({
  show,
});
</script>
<style lang="less" scoped>
.detail {
  width: 950px;
  margin: 0 auto;
  padding-bottom: 80px;
  .title-wrap {
    position: relative;
    padding: 10px;
    font-size: 16px;
    &::before {
      content: "";
      position: absolute;
      top: 16px;
      left: -2px;
      height: 16px;
      width: 4px;
      background-color: var(--theme);
    }
  }
  .file {
    &-type {
      margin-bottom: 10px;
    }
    &-list {
      .file-item {
        display: flex;
        align-items: center;
        padding: 4px 5px;
        background-color: #f5f5f5;
        margin-top: 5px;
        .name {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .op {
          display: flex;
          cursor: pointer;
          div {
            padding: 0 5px;
            color: var(--theme);
          }
          .del {
            color: red;
          }
        }
      }
    }
  }
}
.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  padding: 10px 0;
  text-align: center;
  background-color: #fff;
  border-top: 1px solid #ccc;
}
.pr6 {
  padding-right: 6px;
}
.w160 {
  width: 160px;
}
td {
  vertical-align: center;
}
</style>
